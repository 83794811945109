<template>
  <section
    class="S-product-item j-expose__product-item"
    :data-expose-id="`${index}-${goodsId}`"
    role="listitem"
    :aria-label="goodsName"
    tabindex="0"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div 
      class="S-product-item__wrapper"
    >
      <!-- 图片 -->
      <s-product-item-imgs
        ref="imgcontainer"
        :config="config"
        :no-fsp-class="noFspClass"
        :goods-info="goodsInfo"
        :pretreat-info="pretreatInfo"
        :locate-labels-ctrl="locateLabelsCtrl"
        :locate-labels="locateLabels"
        :show-select="showSelect"
        :select-status="selectStatus"
        :show-similar-popup="showSimilarPopup"
        :language="language"
        :is-rw="constantData.IS_RW"
        :index="index"
        :forbid-jump-detail="forbidJumpDetail"
        :relate-color-info="relateColorInfo"
        :lazy-img="constantData.LAZY_IMG_SOLID_COLOR"
        :css-right="constantData.GB_cssRight"
        :open-target="openTarget"
        :show-spu-img="_showSpuImg"
        :goods-detail-url="goodsDetailUrl"
        :item="item"
        :belt-price-ctrl="beltPriceCtrl"
        :product-design-width="productDesignWidth"
        :promo-label-src="promoLabelSrc"
        :show-img-mask="showImgMask"
        :estimated-price-info="estimatedPriceInfo"
        :no-img-lazy="noImgLazy"
        :discount-info="discountInfo"
        @click-item="handleClickItem"
        @select="handleSelect"
        @focus="hovering = true"
      >
        <!-- 图片底部slot(嵌套) -->
        <template #imgBottom>
          <slot name="imgBottom"></slot>
        </template>
      </s-product-item-imgs>
      <!-- 图片左上角 -->
      <s-product-item-img-top-left
        v-show="!showSelect && !locateLabelsCtrl.upperLeftVisible"
        :goods-info="goodsInfo"
        :constant-data="constantData"
        :language="language"
        :discount-info="discountInfo"
        :flash-sale="flashSale"
        :ada-level="adaLevel"
        :index="index"
        :sold-out="saleout.show"
        :img-upper-left="imgUpperLeft"
        :config="config"
        :show-hot-tag="showHotTag"
      >
        <!-- 图片左上角slot(嵌套) -->
        <template #imgTopLeft>
          <slot name="imgTopLeft"></slot>
        </template>
      </s-product-item-img-top-left>
      <ClientOnly>
        <s-product-item-operation
          v-if="showOperation"
          ref="itemOperation"
          :sold-out="saleout.show"
          :attr-size="attrSize"
          :goods-info="goodsInfo"
          :goods-item-info="goodsItemInfo"
          :config="config"
          :language="language"
          :index="index"
          @submit-add-bag-analysis="handleSubmitAddBagAnalysis"
          @addboard="$emit('addboard', item)"
          @open-quick-add="handleOpenQuickAdd"
        >
          <!-- @add-to-bag="handleAddToBag" -->
          <template #addToBagBtn>
            <slot name="addToBagBtn"></slot>
          </template>
        </s-product-item-operation> 
  
        <!-- 尺码弹框 -->
        <template v-if="config.showQuickAddPanel">
          <s-product-item-quick-add
            v-show="hovering"
            :show="showQuickAdd"
            :attr-size="attrSize"
            :size-rules="sizeRules"
            :goods-info="goodsInfo"
            :language="language"
            :css-right="constantData.GB_cssRight"
            @select-size="handleSelectSize"
            @close-quick-add="handleCloseQuickAdd"
            @submit-add-bag="handleSubmitAddBag"
          />
        </template>
        <!-- 加组弹框 -->
        <s-product-item-wishlist-group
          v-if="config.showAddToBoard"
          v-model:goods-info="goodsInfo"
          :language="language"
          @add-wishlist-group="
            $emit(`addWishlistGroup`, { goodsItem: goodsInfo })
          "
        />
        <s-product-item-loading 
          v-if="showLoading"
          :show="showLoading" 
        />
        <!-- 加车提示 -->
        <s-product-item-tip
          v-if="addToBagResult"
          :tip="addToBagResult"
        />
        <!-- client下 商品图片区域的slot -->
        <slot name="clientGoodsImg"></slot>
      </ClientOnly>
      <!-- 商品图片区域的slot -->
      <slot name="goodsImg"></slot>
    </div>
    <div
      class="S-product-item__info"
      :class="{ 'S-product-item__info_saleout': saleout && saleout.show }"
      :style="bottomInfoStyle"
    >
      <!-- 新型闪购价（新人，专属） -->
      <ProductItemNewFlashPrice
        v-if="
          (config.showNewFlashPrice || config.showOnlyNewFlashPrice) &&
            newFlashPromotion
        "
        :new-flash-promotion="newFlashPromotion"
        :language="language"
        :retail-price="retailPrice"
        :show-retail-price="config.showOnlyNewFlashPrice && !config.hideRetailPrice"
        :hovering="hovering"
        :show-select="showSelect"
        :config="config"
        @delete-item="handleDeleteItem"
      />
      <!-- 商品名称相关 -->
      <s-product-item-goods-name
        v-if="goodsNameVisible"
        ref="namecontainer"
        :config="config"
        :ada-level="adaLevel"
        :index="index"
        :goods-detail-url="goodsDetailUrl"
        :goods-name="goodsName"
        :show-hot-tag="showHotTag"
        :forbid-jump-detail="forbidJumpDetail"
        :front-title="frontTitle"
        :language="language"
        @click-add-bag-icon="handleClickAddBagIcon"
        @click-goods-name="handleClickItem"
      />
      <slot name="goodsNameBottom"></slot>
      <item-decision-attr
        v-if="decisionAttrVisible"
        ref="decisionAttrContainer"
        :goods-detail-url="goodsDetailUrl"
        :forbid-jump-detail="forbidJumpDetail"
        :title-visible="goodsNameVisible"
        :attr-list="slicedDecisionAttr"
        @click-attr="handleClickItem"
      />
      <!-- 榜单 -->
      <s-product-item-ranking-list
        v-if="rankingListVisible"
        :ranking-list="rankingList"
      />
      
      <ClientOnly>
        <!-- 星级 -->
        <s-product-item-rank
          v-if="
            config.showRank &&
              goodsInfo.commentInfo &&
              goodsInfo.commentInfo.comment_rank_average > 0
          "
          :goods-info="goodsInfo"
        />
      </ClientOnly>
      <!-- 价格相关 -->
      <s-product-item-prices
        v-if="!config.hideAllBottomPrices"
        :class="noFspClass ? '' : 'fsp-element'"
        :is-rw="constantData.IS_RW"
        :css-right="constantData.GB_cssRight"
        :public-cdn="constantData.PUBLIC_CDN"
        :config="config"
        :prop-data="propData"
        :pretreat-info="pretreatInfo"
        :index="index"
        :language="language"
        :sale-price="salePrice"
        :retail-price="retailPrice"
        :show-select="showSelect"
        :hovering="hovering"
        :goods-info="goodsInfo"
        :goods-item-info="goodsItemInfo"
        :size-info="sizeInfo"
        :wish-status="wishStatus"
        :show-spu-img="_showSpuImg"
        :is-promo-price="beltPriceCtrl.isPromoPrice"
        :show-new-discount-label="config.showNewDiscountLabel"
        :estimated-price-info="estimatedPriceInfo"
        :discount-info="discountInfo"
        :item="item"
        :sold-out="saleout.show"
        @add-wish-succ="handleAddWishSucc"
        @cancel-wish="handleCancelWish"
        @add-wish-analysis="handleAddWishAnalysis"
        @delete-wish-analysis="handleDeleteWishAnalysis"
        @delete-item="handleDeleteItem"
        @click-add-bag-icon="handleClickAddBagIcon"
      >
        <!-- 价格slot -->
        <template #price>
          <slot name="price"></slot>
        </template>
      </s-product-item-prices>
      <ClientOnly>
        <!-- relate color -->
        <s-product-item-colors
          v-if="
            relatedColor.length > 0 &&
              config.showMultiColor &&
              !config.showMultiColorIcon
          "
          :config="config"
          :ada-level="adaLevel"
          :index="index"
          :language="language"
          :relate-color-list="relatedColor"
          :selected-relate-color="selectedRelateColor"
          :goods-info="goodsInfo"
          :item="item"
          :lazy-img-square="constantData.LAZY_IMG_SQUARE"
          :spu-img-obj="{ spuImg, showSpuImg: _showSpuImg }"
          @relate-color-hover="handleRelateColorHover"
          @click-multi-color="handleClickMultiColor"
        />
        <s-product-item-goods-inner
          v-if="goodsInfo && pretreatInfo"
          :config="config"
          :pretreat-info="pretreatInfo"
        />
        <!-- 商品label -->
        <s-product-item-goods-label
          v-if="goodsInfo && pretreatInfo && createComp"
          :config="config"
          :pretreat-info="pretreatInfo"
          :promotion-info="promotionInfo"
          :show-promotion-msg="showPromotionMsg"
          :ada-level="adaLevel"
          :index="index"
          :goods-info="goodsInfo"
          :goods-bottom="goodsBottom"
          :sold-out="saleout.show"
          :language="language"
          :show-grey-selling-point="config.showGreySellingPoint"
          :is-rw="constantData.IS_RW"
          @expose-selling-point="handleExposeSellingPoint"
          @show-ccc-tsp-badge="handleShowCccTspBadge"
          @show-user-act-emit="handleShowUserActEmit"
          @show-quick-ship="handleShowQuickShip"
        />

        <!-- 商品用户评论关键词标签 -->
        <ProductItemUserCommentKeywordsLabel
          v-if="userCommentKeywordsLabel.labelLang"
          :user-comment-keywords-label-info="userCommentKeywordsLabel"
        />

        <!-- 商品用户行为标签 -->
        <ProductItemUserBehaviorLabel
          v-else-if="userBehaviorLabelLang"
          :config="config"
          :label-lang="userBehaviorLabelLang"
          :pretreat-info="pretreatInfo"
        />

        <!-- 商品底部slot -->
        <slot name="goodsBottom"></slot>
      </ClientOnly>
    </div>
    <!-- 默认内容插槽 -->
    <slot name="goodsMain"></slot>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import SProductItemImgs from './components/ProductItemImgs'
import SProductItemGoodsName from './components/ProductItemGoodsName'
import ItemDecisionAttr from './components/ItemDecisionAttr.vue'
import SProductItemPrices from './components/ProductItemPrices'
import SProductItemColors from './components/ProductItemColors'
import SProductItemRank from './components/ProductItemRank'
import SProductItemImgTopLeft from './components/ProductItemImgTopLeft'
import { clickAddToBagAna } from './analysis'
import { controlItemLabels, controlBeltPrice } from './utils'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'SProductItem',
  provide() {
    return {
      transformImg,
      constantData: this.constantData
    }
  },
  components: {
    ClientOnly,
    SProductItemImgs,
    SProductItemGoodsName,
    ItemDecisionAttr,
    SProductItemPrices,
    SProductItemColors,
    SProductItemRank,
    SProductItemLoading: defineAsyncComponent(() => import('./components/ProductItemLoading.vue')),
    SProductItemTip: defineAsyncComponent(() => import('./components/ProductItemTip.vue')),
    SProductItemOperation: defineAsyncComponent(() => import('./components/ProductItemOperation.vue')),
    SProductItemQuickAdd: defineAsyncComponent(() => import('./components/ProductItemQuickAdd.vue')),
    SProductItemWishlistGroup: defineAsyncComponent(() => import('./components/ProductItemWishlistGroup.vue')),
    SProductItemGoodsInner: defineAsyncComponent(() => import('./components/ProductItemGoodsInner.vue')),
    SProductItemGoodsLabel: defineAsyncComponent(() => import('./components/ProductItemGoodsLabel')),
    SProductItemImgTopLeft,
    ProductItemNewFlashPrice: defineAsyncComponent(() => import('./components/ProductItemNewFlashPrice')),
    ProductItemUserBehaviorLabel: defineAsyncComponent(() => import('./components/ProductItemUserBehaviorLabel')),
    ProductItemUserCommentKeywordsLabel: defineAsyncComponent(() => import('./components/ProductItemUserCommentKeywordsLabel')),
    SProductItemRankingList: defineAsyncComponent(() => import('./components/ProductItemRankingList')),
  },
  props: {
    /**
     * config 配置展示选项，埋点
     * @param {String} daEventClickId 点击埋点id，不传默认2-3-1
     * @param {Boolean} useOwnClickExposeAnalysis 是否使用自己的点击，曝光埋点
     * @param {String} code ga 埋点需要
     * @param {String} from sa的activity_from
     * @param {Boolean} clickItemStopPropagation 点击商品图阻止冒泡
     * @param {Boolean} hideGoodsName 商品名称
     * @param {Boolean} hideOperationBtn 操作按钮 add to bag, find similar
     * @param {Boolean} hidePromotionIcon 促销icon
     * @param {Boolean} hideNormalPrices 隐藏普通价格（售卖价，零售价）
     * @param {Boolean} hideRetailPrice 隐藏零售价（划线价）
     * @param {Boolean} hideDiscountBadge 隐藏左上角折扣标签
     * @param {Boolean} isCamelCasePrice 在售价格字体是否驼峰
     * @param {Boolean} showGoodsNameTwoLine 商品名称展示两行
     * @param {Boolean} showBadge 角标 new，top rate，low in stock goodsItemInfo里面控制数据的处理
     * @param {Boolean} showMultiColor 色块
     * @param {Boolean} showMultiColorIcon 多色块图标
     * @param {Boolean} showAddToBoard 加组弹窗
     * @param {Boolean} showAddWishlist 收藏按钮
     * @param {Boolean} showFindSimilar find similar按钮
     * @param {Boolean} showAddBoard add to board按钮
     * @param {Boolean} showReducePrice 降价
     * @param {Boolean} showDelete 删除icon
     * @param {Boolean} showSeriesBrand 品牌系列
     * @param {Boolean} showPromotion 促销
     * @param {Boolean} showLeftStock 剩余库存（0-10 不包含首尾） romwe
     * @param {Boolean} showLeftStockShein 剩余库存（0-10 不包含首尾） shein
     * @param {Boolean} showSellingPoint 卖点角标
     * @param {Boolean} showLocalSeller 本地发货标签
     * @param {Boolean} showQuickShip 快速发货标签
     * @param {Boolean} showAddBagAfterGoodsName 商品名称后，快速加车icon
     * @param {Boolean} showAddBagAfterGoodsPrice 价格后，快速加车icon
     * @param {Boolean} showRank 星级
     * @param {Boolean} showQuickAddPanel 尺码面板
     * @param {Boolean} showSpuImg spu图
     * @param {Boolean} showSheinClubDiscountValue 付费会员折扣 ***废弃***
     * @param {Boolean} showSheinClubDiscountValueAfterPrice 付费会员折扣显示在价格右侧 ***废弃***
     * @param {Boolean} showSheinClubPriceOnSale 付费会员价，占用原来的销售价
     * @param {Boolean} showSheinClubPrice 付费会员价格
     * 以下两个跟新型闪购价格相关的配置，根据不同场景，一般来说配置其中一项即可，都配置会相互冲突
     * @param {Boolean} showNewFlashPrice 新型闪购价
     * @param {Boolean} showOnlyNewFlashPrice 只展示新型闪购价，隐藏原来的销售价
     * @param {Boolean} showNewFlashPriceOnSale 新型闪购价，占用原来的销售价
     * 
     * 新型闪购的3种标签
     * @param {Boolean} showNewFlashLabel 新型闪购底部标签（New user price, Exclusive）- 三图推荐使用
     * @param {Boolean} showNewFlashLabelBelowPrice 价格下面的新型闪购标签
     * @param {Boolean} showNewFlashLabelUpPrice 价格上方的新型闪购标签
     * 
     * @param {Boolean} showDiscountLabelAfterPrice 价格后面展示折扣标签
     * @param {Boolean} showPromoLabel 大促标签
     * @param {Boolean} showBestDealLabel 保留款
     * @param {Boolean} showUserBehaviorLabel 用户行为标签
     * @param {Boolean} showUserCommentKeywordsLabel 用户评论关键词标签
     * @param {Boolean} showBeltLabel 腰带
     * @param {Boolean} showRankingList 榜单
     * @param {Boolean} showCateName 分类名称
     * @param {Boolean} showDecisionAttr 决策属性
     * @param {Boolean} hideDiscountLabel 超出一行展示的时候隐藏超出的标签（原始需求：隐藏折扣标签）
     * @param {Boolean} showEstimatedPrice 到手价（满足门槛和未满足门槛）
     * @param {Boolean} showEstimatedPriceReachThreshold 到手价（仅满足门槛）
     * 
     * 
     * @param {Boolean} standardPrice wiki：pageId=1347754607  旧商卡支持合规价

     * @param {Boolean} ableDeleteWishlist 支持取消收藏
     * @param {Boolean} disableMainimgJump 禁止点击主图和标题跳转商详, 透传事件clickItem
     * @param {Boolean} disableMultiColorJump 禁止多色块跳转，透传事件clickMultiColor
     * @param {Boolean} disableImmediateAddBag 单SKU禁止直接加车（所有加车走加车弹窗）
     * @param {Boolean} configReady 所有配置已经准备好，才开始计算商品底部角标（调用一定要确保，此时其他配置是最终结果）
     * @param {Object} style 样式相关
     *    @param {String} salePriceFontSize 售卖价字号, 例子：12px
     *    @param {String} salePriceColor 售卖价颜色, 例子：#FF4C3A
     *    @param {String} lineThroughPriceFontSize 划线价字号, 例子：12px
     *    @param {String} discountLabel 左上角促销折扣角标样式，默认normal。其他选项 flashSale
     *    @param {Object} operationBtn 操作按钮样式，例子：{ margin: 0 13px; }
     *    @param {Object} priceCtn 价格容器 例子：{ height: '24px', lineHeight: '24px' }
     *    @param {Object} addBagIcon 加车icon样式，例子：{ size: 'default'/'middle', backgroundColor: #000}
     * @param {Boolean} showSellAttr 销售属性展示。（收藏）
     * @param {String} productDesignWidth 商品设计稿宽度 (裁切使用)
     * @param {String} showSheinappwordsize 价格字体ABT
     * @param {String} showGoodsSpace 商品下面内容标签间距ABT
     * @param {String} showPclistCropping 商品图裁剪ABT
     * @param {String} showLabelColumn 列表标签云显示与否ABT
     * @param {String} showHotLocals Hot标位置
     * */
    config: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    adaLevel: {
      type: Number,
      default: 0,
    },
    // item实例
    goodsItemInfo: {
      type: Object,
      default: () => ({}),
    },
    // 进入可选中模式。隐藏操作按钮，收藏数和删除icon。修改a标签href，解除鼠标右键功能
    showSelect: {
      type: Boolean,
      default: false,
    },
    // 展示找相似弹窗，需禁用操作按钮
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    // 选中状态
    selectStatus: {
      type: Boolean,
      default: false,
    },
    // 控制loading
    loading: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    // LAZY_IMG, LAZY_IMG_SQUARE, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW
    constantData: {
      type: Object,
      default: () => ({}),
    },
    bottomInfoStyle: {
      type: Object,
      default: () => ({ minHeight: '98px' }),
    },
    // 收藏状态
    wishStatus: {
      type: Boolean,
      default: false,
    },
    // 专题会注入该对象
    propData: {
      type: Object,
      default: () => ({}),
    },
    // 窗口打开类型
    openTarget: {
      type: String,
      default: '_self',
    },
    // 商品设计稿宽度
    productDesignWidth: {
      type: [String, Number],
      default: 0,
    },
    // 不使用fsp点位
    noFspClass: {
      type: Boolean,
      default: false,
    },
    // 不使用图片懒加载
    noImgLazy: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hovering: false,
      selectedRelateColor: '',
      relateColorInfo: null,
      preViewGoodsInfo: null,
      clickAddToBag: false,
      sizeInfo: {},
      adaption: 11, // adaAdaption Value
      createComp: true, // 用于重新初始化组件
      // goodsDetailUrl: 'javascript:void(0);',
      imgRatio: '',
    }
  },
  computed: {
    goodsInfo() {
      const getInfoLoading = this.relateColorInfo?.pretreatInfo?.getInfoLoading
      return this.relateColorInfo && (getInfoLoading === true || getInfoLoading === false)
        ? getInfoLoading && this.preViewGoodsInfo
          ? this.preViewGoodsInfo
          : this.relateColorInfo
        : this.item
    },
    isRw() {
      return this.constantData.IS_RW
    },

    locateLabels () {
      return this.goodsInfo.pcStandardView?.locateLabels || {}
    },
    locateLabelsCtrl () {
      return controlItemLabels(this.locateLabels, {
        config: this.config,
        discountInfo: this.discountInfo,
        flashSale: this.flashSale,
      })
    },
    beltPriceCtrl () {
      return controlBeltPrice(this.config, {
        isSoldOut: this.saleout.show,
        pretreatInfo: this.pretreatInfo
      })
    },

    goodsNameVisible () {
      return this.goodsName && !this.config.hideGoodsName
    },
    rankingListVisible () {
      return !!this.rankingList
    },
    decisionAttrVisible () {
      return this.config.showDecisionAttr && this.slicedDecisionAttr.length >= 2 && !this.rankingListVisible
    },
    slicedDecisionAttr () {
      if (Array.isArray(this.goodsInfo?.decisionAttrList)) {
        return this.goodsInfo.decisionAttrList.slice(0, 3)
      }
      return []
    },
    decisionAttrAna () {
      return this.slicedDecisionAttr.map(item => `show_key_Attribute_${item.attrId}_${item['name-en']}`).join('|')
    },

    spuImg() {
      return ((this.goodsInfo?.spu_image || [])[0]?.medium_image) || ''
    },
    _showSpuImg() {
      return !!(this.config.showSpuImg && this.spuImg && this.spuImg !== 'filteredSpuImg')
    },
    goodsInfoForSize() {
      return this.sizeInfo?.goodsInfoForSize || undefined
    },
    /** 操作按钮展示 */
    showOperation() {
      return ((this.hovering && !this.showQuickAdd && !this.showLoading && !this.addToBagResult && !this.forbidJumpDetail && !this.config.hideOperationBtn) || this.saleout.show) 
      && !this.showSelect 
      && !this.showSimilarPopup
    },
    showLoading() {
      return (
        this.getInfoLoading || this.addToBagLoading || this.getAttrSizeInfoLoading || this.loading
      )
    },
    getInfoLoading() {
      const getInfoLoding = this.relateColorInfo?.pretreatInfo?.getInfoLoading
      return this.relateColorInfo
        ? getInfoLoding === true || getInfoLoding === false
          ? getInfoLoding
          : true
        : false
    },
    getAttrSizeInfoLoading() {
      return Object.prototype.hasOwnProperty.call(this.pretreatInfo, 'getAttrSizeInfoLoading')
        ? this.pretreatInfo.getAttrSizeInfoLoading
        : false
    },
    addToBagLoading() {
      return this.pretreatInfo?.addToBagLoading || false
    },
    goodsId() {
      return Number(this.goodsInfo?.goods_id)
    },
    goodsName() {
      const cateName = this.config.showCateName && this.goodsInfo?.cat_name
      return cateName || this.goodsInfo?.goods_name || ''
    },
    mainImg() {
      return this._showSpuImg ? this.spuImg : this.goodsInfo?.goods_img || ''
    },
    retailPrice() {
      return this.goodsInfoForSize?.retailPrice || this.goodsInfo.retailPrice || {}
    },
    salePrice() {
      return this.goodsInfoForSize?.salePrice || this.goodsInfo.salePrice || {}
    },
    showSalePrice() {
      return this.retailPrice.amount !== undefined && this.retailPrice.amount != this.salePrice.amount
    },
    relatedColor() {
      return this.item.relatedColor || []
    },
    promotionInfo() {
      let promotionInfo = this.pretreatInfo.mixPromotionInfo || []
      return promotionInfo.find(item => item.lable && item.typeId) || null
    },
    showPromotionMsg() {
      return !!this.promotionInfo?.lable
    },
    /** 默认null 没有请求数据, 空数组没有数据 */
    attrSize() {
      return this.pretreatInfo.attrSize || null
    },
    sizeRules() {
      return this.pretreatInfo.sizeRules || {}
    },
    /**
     * 业务数据 预处理对象
     */
    pretreatInfo() {
      return this.goodsInfo.pretreatInfo || {}
    },
    flashSale() {
      return this.pretreatInfo?.flashsale
    },
    /** 左上角折扣角标 */
    discountInfo() {
      const { price, discountPercentString, discount_amount } = this.goodsInfo.sheinClubPromotionInfo || {}
      const sheinClubPriceWithSymbolVal = this.config.showSheinClubPriceOnSale && price?.amountWithSymbol // 付费会员占用售价坑位的价格
      if (sheinClubPriceWithSymbolVal) {
        return { unitDiscountString: discountPercentString, discountPrice: discount_amount?.amountWithSymbol, show: true }
      }
      return { 
        ...this.goodsInfoForSize?.pretreatInfo?.discountInfo || this.pretreatInfo?.discountInfo || {},
        discountPrice: this.goodsInfo?.discountPrice?.amountWithSymbol
      }
    },
    /** 标题前角标 new 内容其实是配置的，后期可以随意新增 */
    frontTitle() {
      return (this.pretreatInfo && this.pretreatInfo.frontTitle) || null
    },
    /** 图片左上角角标 new 内容其实是配置的，后期可以随意新增 */
    imgUpperLeft() {
      return (this.pretreatInfo && this.pretreatInfo.imgUpperLeft) || null
    },
    /** 商品名字左侧 hot标 */
    showHotTag() {
      if((this.pretreatInfo?.tspLabels?.hot_color || this.goodsInfo?.tspLabels?.hot_color) == 1) {
        return this.config.showHotLocals ? 2 : 1
      }
      return 0
    },
    /** 图片左下角角标 new top rated 内容其实是配置的，后期可以随意新增 */
    imgBottomLeft() {
      return (this.pretreatInfo && this.pretreatInfo.imgBottomLeft) || null
    },
    /** 商品下方角标 low in stock 内容其实是配置的，后期可以随意新增*/
    goodsBottom() {
      return (this.pretreatInfo && this.pretreatInfo.goodsBottom) || null
    },
    // 系列优先级高于品牌
    seriesOrBrandLogoRightTop() {
      const { seriesLogo, brandLogo } = this.pretreatInfo
      return seriesLogo || brandLogo || null
    },
    plusSize() {
      return this.pretreatInfo?.plusSize || ''
    },
    saleout() {
      return this.pretreatInfo?.saleout || { show: false }
    },
    showQuickAdd() {
      return this.clickAddToBag && Array.isArray(this.attrSize)
    },
    addToBagResult() {
      return this.pretreatInfo?.addToBagResult || ''
    },
    // ADA可选元素自适应排位控制
    adaAdaptionIndex() {
      return [
        this.discountInfo,
        this.seriesOrBrandLogoRightTop,
        true,
        this.flashSale,
        this.imgBottomLeft,
        !this.isRw && this.plusSize,
        true,
        this.showSalePrice,
        this.retailPrice?.amountWithSymbol,
        true,
        this.relatedColor.length > 0,
        this.showPromotionMsg,
      ]
    },
    forbidJumpDetail() {
      return this.showSelect || this.config.disableMainimgJump
    },
    newFlashPromotion() {
      return this.pretreatInfo?.newFlashPromotion || null
    },
    promoLabelSrc() {
      return this.config.showPromoLabel && this.goodsInfo?.promoLabel?.src 
        ? this.goodsInfo.promoLabel.src 
        : ''
    },
    userBehaviorLabelLang() {
      return this.pretreatInfo?.userBehaviorLabel?.labelLang
    },
    userCommentKeywordsLabel() {
      return this.pretreatInfo?.userCommentKeywordsLabel ?? {}
    },
    rankingList() {
      return this.config.showRankingList && this.pretreatInfo?.rankingList
    },
    // 到手价信息
    estimatedPriceInfo () {
      return this.goodsInfo.estimatedPriceInfo || {}
    },
    // 图片蒙层
    showImgMask() {
      return Boolean(this.config.showImgMask)
    },
    // 商品详情页链接
    goodsDetailUrl() {
      let jumpUrl = this.goodsInfo?.detail_url
      return jumpUrl || 'javascript:void(0)'
    },
  },
  watch: {
    goodsInfo(val, oldVal) {
      if (Number(val.goods_id) !== Number(oldVal.goods_id)) {
        this.createComp = false
        this.setElAttr()
        this.$nextTick(() => {
          window.dispatchEvent(new Event('scroll'))
          this.createComp = true
        })
      }
    },
    addToBagResult(val) {
      if (!val) return
      window.setTimeout(() => {
        this.pretreatInfo.addToBagResult = ''
      }, 1200)
    },
    async showQuickAdd(val) {

      if (!this.imgRatio) {
        await this.getImgRatio()
      }

      if (!this.config.showQuickAddPanel && val) {
        this.clickAddToBag = false
        this.$emit('clickAddToBag', {
          item: this.item,
          goodsItem: this.goodsInfo,
          target: this.$refs.imgcontainer.$el,
          index: this.index,
          imgRatio: this.imgRatio
        })
      }
    },
    // 'goodsInfo.detail_url': {
    //   handler(val) {
    //     if (val) {
    //       this.$nextTick(() => {
    //         this.getProductDetailUrl()
    //       })
    //     }
    //   }
    // }
  },
  created() {
    if (!this.$isServer) {
      this.initSelectedRelatedColor()
      // this.getProductDetailUrl()
    }
  },
  mounted() {
    this.setElAttr()
  },
  methods: {
    setElAttr() {
      this.setAttrForAnalysis((el, isImageContainer) => {
        if (!this.config.useOwnClickExposeAnalysis) {
          el.setAttribute('da-event-click', this.config.daEventClickId || '2-3-1')
          isImageContainer && this.config.daEventExposeId && el.setAttribute('da-event-expose', this.config.daEventExposeId)
        }
        const mixPromotionInfo = this.pretreatInfo?.mixPromotionInfo || []

        el.setAttribute('index', this.index)
        el.setAttribute('data-spu', this.goodsInfo?.productRelationID || '')
        el.setAttribute('data-sku', this.goodsInfo?.goods_sn || '')
        el.setAttribute('data-id', this.goodsInfo?.goods_id || '')
        el.setAttribute('data-title', this.goodsInfo?.goods_name || '')
        el.setAttribute('data-cat_id', this.goodsInfo?.cat_id || '')
        el.setAttribute('data-rec_mark', this.goodsInfo?.ext?.rec_mark || '')
        el.setAttribute('data-extra_mark', this.goodsInfo?.ext?.extra_mark || '')
        el.setAttribute('data-other_ext_mark', this.goodsInfo?.ext?.other_ext_mark || '')
        el.setAttribute('data-other_d_ext_mark', this.goodsInfo?.dynamic_ext?.other_d_ext_mark || '')
        el.setAttribute('data-us-price', this.goodsInfo?.salePrice?.usdAmount || '')
        el.setAttribute('data-price', this.goodsInfo?.salePrice?.amount || '')
        el.setAttribute('data-us-origin-price', this.goodsInfo?.retailPrice?.usdAmount || '')
        el.setAttribute('data-series-brand', !this.promoLabelSrc && this.pretreatInfo?.seriesOrBrandAnalysis || '')
        el.setAttribute('data-discount', this.goodsInfo?.unit_discount || '')
        el.setAttribute('data-label', this.pretreatInfo?.label || '')
        el.setAttribute('data-brand', this.goodsInfo?.brand?.name || '')
        el.setAttribute('data-brand-code', this.goodsInfo?.brand?.brand_code || '')
        el.setAttribute('data-page', this.goodsInfo?.thisPage || '') // 当前页码
        el.setAttribute('data-show-exclusive-price', this.goodsInfo?.exclusivePromotionPrice || '')
        el.setAttribute('data-video', this.goodsInfo?.video_url ? 'video_icon' : '')
        el.setAttribute('data-spu-img', this.spuImg ? (this._showSpuImg ? 'spupic_1' : 'spupic_0') : '')
        el.setAttribute('data-mall_tag_code', `${this.pretreatInfo?.mallTagsInfo?.mall_tags || ''}_${this.goodsInfo?.mall_code || '-'}`)
        el.setAttribute('data-store_code', this.goodsInfo?.store_code ?? '')
        el.setAttribute('data-promotion-id', mixPromotionInfo.map(item => item?.id).join('`') || '')
        el.setAttribute('data-type-id', mixPromotionInfo.map(item => item?.typeId).join('`') || '')
        el.setAttribute('data-quickship', this.goodsInfo?.quickship || '')
        el.setAttribute('data-best-deal', this.beltPriceCtrl.showBestDeal ? 1 : '')
        el.setAttribute('data-follow-label', this.beltPriceCtrl.followLabelVisible ? 1 : '')
        el.setAttribute('data-promo-label', this.promoLabelSrc ? 1 : '')
        el.setAttribute('data-user-behavior-label', this.pretreatInfo?.userBehaviorLabel?.ana || '')
        el.setAttribute('data-belt-label', this.pretreatInfo?.beltLabel?.ana || '')
        el.setAttribute('data-locate-labels', this.locateLabelsCtrl.locateLabelsAna || '')
        el.setAttribute('data-ranking-list', this.pretreatInfo?.rankingList?.ana || '')
        el.setAttribute('data-decision-attr', this.decisionAttrVisible ? this.decisionAttrAna : '')
        el.setAttribute('data-user-comment-keywords-label', this.pretreatInfo?.userCommentKeywordsLabel?.ana || '')
        el.setAttribute('local_goods_id', this.goodsInfo?.local_goods_id || '')

        // 31品类限定商家限时直降 wiki.pageId=1198803653
        let showSellerLimitedLabel = ''
        if (mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)) {
          showSellerLimitedLabel = 'show_seller_limited_label'
        }
        el.setAttribute('data-show-seller-limited-label', showSellerLimitedLabel)
        
        const labelHot = ((this.goodsNameVisible && !this.frontTitle?.code && this.showHotTag === 2) || (!this.imgUpperLeft?.code && !this.locateLabels?.UPPER_LEFT_LABEL && this.showHotTag === 1)) ? 'show_label_hot' : ''
        // console.log(this.goodsNameVisible, '|', this.frontTitle?.code, '|', this.showHotTag, '|', this.imgUpperLeft?.code, '|', this.locateLabels?.UPPER_LEFT_LABEL, '展示Hot条件信息')
        el.setAttribute('data-show-label-hot',  labelHot)
        // el.setAttribute('data-show-label-hot',  'show_label_hot')
        const badges = []
        const dataBadges = el.getAttribute('data-badges')
        const upLeftBadges = this.goodsName && this.config.showTitle && this.frontTitle?.code || this.imgUpperLeft?.code
        if (dataBadges) badges.push(dataBadges)
        if (upLeftBadges) badges.push(upLeftBadges)
        el.setAttribute('data-badges', badges)

        if (this.saleout && this.saleout.show) {
          el.setAttribute('data-sold-out', 1)
        }
        if (this.config.showSellAttr) {
          el.setAttribute('data-sell-attr', this.pretreatInfo?.sellAttr ? `attribute_show_${this.pretreatInfo.sellAttr}` : 'attribute_null')
        }

        // 到手价的一些信息
        const { estimatedPrice, satisfied, showABPrice, isEstimatedPrice } = this.estimatedPriceInfo || {}
        const estimatedPriceUsdAmount = estimatedPrice?.usdAmount // 到手美元价
        const satisfiedVal = showABPrice ? 3 : ([2, 1][satisfied] || '')
        if (satisfiedVal && estimatedPriceUsdAmount && isEstimatedPrice) {
          el.setAttribute('data-estimated-price', `${satisfiedVal}\`${estimatedPriceUsdAmount}`)
        }
        if(this.item.isHypernymGoods === true) {
          el.setAttribute('data-hypernym-goods', 1)
        }
      })
    },
    /**
     * 自定义埋点字段
     * @param {Array} attrArr [{ attr: xxx, value: xxx }]
     */
    customizeElAttr(attrArr = []) {
      const elMain = this.$refs.imgcontainer.$el
      const elName = this.$refs.namecontainer?.$refs?.namelink
      attrArr.forEach(({ attr, value }) => {
        elMain && elMain.setAttribute(attr, value)
        elName && elName.setAttribute(attr, value)
      })
    },
    initSelectedRelatedColor() {
      this.relatedColor &&
        this.relatedColor.forEach((cur) => {
          if (Number(cur.goods_id) === this.goodsId) {
            this.selectedRelateColor = Number(cur.goods_id)
          }
        })
    },
    handleRelateColorHover(colorGoodsId, goodsId) {
      // 选择多颜色信息，初始化快速加车弹框, 尺码选择信息
      this.selectedRelateColor = Number(colorGoodsId)
      this.setRelateColorInfo(this.selectedRelateColor)
      this.clickAddToBag = false
      this.sizeInfo = {}
      if (this.goodsInfo?.pretreatInfo?.addWishlistGroup) {
        this.goodsInfo.pretreatInfo.addWishlistGroup = 0
      }
      this.$emit('relateColorHover', colorGoodsId, goodsId)
    },
    setRelateColorInfo(selectedRelateColor) {
      const { cat_name } = this.goodsInfo
      let goodsItem = {}
      // 切换的多颜色跟主商品是同个skc，则直接用主商品数据
      if (Number(selectedRelateColor) === Number(this.item.goods_id)) {
        goodsItem = this.item
      } else {
        goodsItem = this.relatedColor.find(
          (item) => Number(item.goods_id) === Number(selectedRelateColor)
        )
      }

      this.preViewGoodsInfo = this.goodsInfo

      this.$nextTick(() => {
        if (!goodsItem.cat_name && cat_name) {
          goodsItem.cat_name = cat_name
        }
        this.relateColorInfo = goodsItem
        this.goodsInfo.spu_image = ''
      })
    },
    handleClickMultiColor(currentColorInfo) {
      this.$emit('clickMultiColor', currentColorInfo)
    },
    async handleOpenQuickAdd() {
      if (this.addToBagLoading) return false
      
      clickAddToBagAna({ target: this.$refs.imgcontainer.$el }) //重复埋点?

      if (!this.imgRatio) {
        await this.getImgRatio()
      }
      this.$emit('openQuickAdd', {
        item: this.goodsInfo,
        goodsItem: this.goodsInfo,
        index: this.index,
        imgRatio: this.imgRatio,
        target: this.$refs.imgcontainer.$el
      })
    },
    async handleAddToBag({ status, data = {} }) {
      this.clickAddToBag = true
      
      const { goodsItem, response } = data

      if (status == 'beforeRequest') {
      /**
       * wiki.pageId=1150339130
       *  click_goods_list_addcar 事件多发
       */
        clickAddToBagAna({ target: this.$refs.imgcontainer.$el }) //重复埋点?
        this.$emit('addBagBeforeRequest', { goodsItem, index: this.index, target: this.$refs.imgcontainer.$el })
      } else if (status == 'successRequest') {
        this.clickAddToBag = false
        this.$emit('addBagSuccess', { goodsItem, response })
      }

      if (!this.imgRatio) {
        await this.getImgRatio()
      }

      this.$emit('openQuickAdd', {
        item: goodsItem,
        goodsItem: this.goodsInfo,
        index: this.index,
        imgRatio: this.imgRatio,
        target: this.$refs.imgcontainer.$el,
      })
    },
    handleSelectSize({ sizeInfo } = {}) {
      this.sizeInfo = sizeInfo
    },
    handleCloseQuickAdd() {
      this.clickAddToBag = false
    },
    handleSubmitAddBag({ selectedSize = '' } = {}) {
      if (this.addToBagLoading) return
      this.$refs.itemOperation.submitAddBag({
        goodsItem: this.goodsInfo,
        hasAttr: true,
        selectedSize
      })
    },
    handleAddWishSucc(goodsInfo) {
      this.$emit('addWishSucc', goodsInfo)
    },
    handleCancelWish(goodsInfo) {
      this.$emit('cancelWish', goodsInfo)
    },
    handleSubmitAddBagAnalysis(payload) {
      this.$emit('submitAddBagAnalysis', {
        ...payload,
        target: this.$refs.imgcontainer.$el,
      })
    },
    handleAddWishAnalysis(payload) {
      this.$emit('addWishAnalysis', {
        ...payload,
        target: this.$refs.imgcontainer.$el,
      })
    },
    handleDeleteWishAnalysis(payload) {
      this.$emit('deleteWishAnalysis', payload)
    },
    getAdaAdaptionIndex(index) {
      return this.adaAdaptionIndex[index]
        ? this.adaAdaptionIndex.slice(0, index + 1).filter((_) => _).length - 1
        : null
    },
    handleDeleteItem() {
      this.$emit('deleteItem', this.item)
    },
    async getImgRatio() {
      const goodsDetailData = {
        productDetailUrl: this.goodsDetailUrl,
        imgSrc: this.mainImg,
        getUrlWithImgRatio: true
      }

      const { url, imgRatio } = await getProductDetailUrl(goodsDetailData)

      this.imgRatio = imgRatio

      return url
    },
    // 点击图片或者名称或者决策属性
    async handleClickItem({ event, target }) {

      // 阻止默认的 href 链接跳转，追加 imgRatio 参数
      event.preventDefault()

      const url = await this.getImgRatio()

      if (this.config.clickItemStopPropagation) event.stopPropagation()
      this.$emit('clickItem', { ...this.goodsInfo, target, index: this.index, imgRatio: this.imgRatio, goodsDetailPageUrl: url })

      // 如果商品项目 href 是 javascript:void(0) 则不跳转
      if (target?.href?.includes('javascript:void(0)')) {
        return
      }
      this.openTarget === '_blank'
        ? window.open(url)
        : window.location.href = url
    },
    handleClickAddBagIcon() {
      if (!this.saleout.show) {
        this.handleOpenQuickAdd()
      }
    },
    // 处理选中
    handleSelect(payload) {
      this.$emit('selectItem', payload)
    },
    // 卖点埋点
    handleExposeSellingPoint(text) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-selling-point', text)
      })
    },
    // cccTsp埋点
    handleShowCccTspBadge(isShow) {
      const badges = []
      if (this.imgBottomLeft?.code) badges.push(this.imgBottomLeft.code)
      if (this.goodsBottom?.code && isShow) badges.push(this.goodsBottom.code)

      this.setAttrForAnalysis((el) => {
        const badges = []
        const dataBadges = el.getAttribute('data-badges')
        const showBadges = badges.join('|')
        if (dataBadges) badges.push(dataBadges)
        if (showBadges) badges.push(showBadges)
        el.setAttribute('data-badges', badges.join('|'))
      })
    },
    handleShowUserActEmit({ label }) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-user-act', label)
      })
    },
    handleShowQuickShip() {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-quick-ship', 'quickship_1')
      })
    },
    handleShowGoodsLabel(val) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-sales-label', val)
      })
    },
    setAttrForAnalysis(fn) {
      const setElAttr = (el, isImageContainer = true) => {
        typeof fn === 'function' && fn(el, isImageContainer)
      }
      this.$refs.imgcontainer?.$el && setElAttr(this.$refs.imgcontainer.$el)
      this.$refs.namecontainer?.$refs?.namelink &&
        setElAttr(this.$refs.namecontainer.$refs.namelink, false)

      const $attrLink = this.$refs.decisionAttrContainer?.$refs?.attrLink
      $attrLink && setElAttr($attrLink, false)
    },
  },
  emits: [
    'addboard',
    'clickAddToBag',
    'clickMultiColor',
    'relateColorHover',
    'addBagBeforeRequest',
    'addBagSuccess',
    'submitAddBagAnalysis',
    'addWishSucc',
    'addWishAnalysis',
    'deleteWishAnalysis',
    'deleteItem',
    'clickItem',
    'selectItem',
    'addWishlistGroup',
    'openQuickAdd',
    'cancelWish'
  ],
}
</script>

<style lang="less">
@import "./style/icon.less";
.S-product-item {
  font-size: 0;
  // &__img-ctn_crop-120 {
  //   padding-bottom: 120%;
  // }
  &__info {
    min-height: 74px;

    &_saleout {
      &> :not(.S-product-item__relatecolor-container) {
        opacity: 0.3;
      }
    }
  }
}
.S-product-item__wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
</style>
